import img from "../../images/services/Pulizie-Uffici-Manutenpul.webp";
import Service from "../../components/Service";
import {list_style,pageStyles} from '../../styles/style';
import Footer from "../../components/Footer";
import Downbar from "../../components/down_footer_pulizie";
import Navbar from "../../components/Navbar";
import Banner from "../../components/Banner";
import Works from "../../components/Works";
import { Helmet } from "react-helmet";
import * as React from "react";
import "../../styles/main.css";
import FloatButton from "../../components/FloatButton";

// data

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <FloatButton />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Manutenpul | Pulizia Appartamenti</title>
        <meta name="keywords" content="impresa di pulizie milano, impresa di pulizie milano prezzi, impresa pulizie costi milano, impresa di pulizie cesano boscone, impresa di sanificazione, impresa di pulizie lodi" />
      </Helmet>
      <Navbar></Navbar>
      <Downbar></Downbar>
      <Banner pageName="Pulizie Appartamenti" prepageName="Servizio" postpageName="Pulizie Appartamenti" />
      <Service
        title="Pulizie appartamenti"
        text="Gli uffici sono ambienti di lavoro in cui trascorriamo molto tempo, 
        rappresentano quasi la nostra seconda casa, per cui è assolutamente necessario 
        procedere ad accurate pulizie degli uffici. MANUTENPUL Srls si occupa da anni con 
        professionalità e scrupolosità delle pulizie degli uffici, affinché i lavoratori possano 
        lavorare in un ambiente pulito e gradevole. Realizziamo servizi di pulizie uffici offrendo 
        oltre ad interventi di igienizzazione generali degli spazi, anche servizi di logistica che 
        comprendono: gestione del magazzino, imballaggio, carico e scarico e spedizioni. Adattandoci 
        alle necessità del committente, siamo in grado di fornire servizi di pulizie uffici perfetti, 
        pulendo tutti gli arredi ed i mobili delle varie stanze in modo veloce ad accurato"
        img={img}
      >
        <br />
        <br />
        Hai paura a trovare la donna delle pulizie adatta al tuo appartamento?
        Non ti fidi di un’estranea senza garanzie? Con noi di Manutenpul puoi
        stare tranquillo;
        <lu style={list_style}>
          <li><span className="material-icons">military_tech</span>Anni di esperienza</li>
          <li><span className="material-icons">sentiment_very_satisfied</span>Referenziati</li>
          <li><span className="material-icons">task_alt</span>Certificati da noi</li>
        </lu>
          Con noi avrai un servizio personalizzato
          
          <br/><br/>
          <h3>Pulizie post ristrutturazione</h3>
          
          Hai ristrutturato la casa e hai bisogno di un professionista
          Manutenpul?
        <br />
        <br />
        Cambio inquilino 
        Pulizie con un nostro professionista Manutenpul è
        quello che serve per dare un volto nuovo alla tua casa.
        <br />
        <br />
        Pulizie generali
        Non hai tempo di pulire? I professionisti di Manutenpul
        fanno pulizie approfondite e ordinarie. 
        Pulizie seconde case
        Casa chiusa
        tutto l’anno? Via la polvere con i professionisti di Manutenpul
        Appartamenti di Airbnb
        Cerchi un collaboratore per pulire la tua casa di
        Airbnb, tra un inquilino e l’altro? I professionisti di Manutenpul sanno
        fare anche questo.
      </Service>
      <Works />
      <Footer></Footer>
    </main>
  );
};

export default IndexPage;
